<template>
  <main-content>
    <PaginationTable
      ref="pageTable"
      :api="findPage"
      :isPaging="false"
      height="calc(100vh - 200px)"
    >
      <el-table-column
        label="支付平台"
        prop="payPlatformName"
        min-width="100"
      />
      <el-table-column label="平台标识" prop="payPlatform" min-width="100" />
      <el-table-column label="支付方式" prop="payTypeName" min-width="180">
      </el-table-column>
      <el-table-column label="支付标识" prop="payType" min-width="160">
      </el-table-column>
      <el-table-column label="默认商户号" prop="appId" min-width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.payeeAppType === 'POLLING'">{{ scope.row.pollingRuleName || '-' }}</span>
          <span v-else>{{ scope.row.appId || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="适用客户" prop="customerIds" min-width="130">
        <template slot-scope="scope">
          <span>{{ scope.row.customerIds | customerText }}</span>
        </template>
      </el-table-column>
      <el-table-column label="适用场景" prop="scenes" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.scenes | scenesText }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" min-width="100">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleEditItem(row)">编辑</el-button>
        </template>
      </el-table-column>
    </PaginationTable>

    <!-- 编辑弹框 -->
    <pay-dialog
      :isShow="isShow"
      :changeStatus="changeShowStatus"
      :currentPaymentItemData="currentPaymentItemData"
    />
  </main-content>
</template>

<script>
import PaginationTable from '@/components/PaginationTable.vue';
import MainContent from '@/components/MainContent.vue';
import payCenterApi from '@/api/paycenter';
const PayDialog = () => import('../components/PayDialog.vue');
export default {
  components: { PaginationTable, MainContent, PayDialog },
  data() {
    return {
      isShow: false,
      currentPaymentItemData: null,
    };
  },
  filters: {
    customerText(customerIds) {
      if (!customerIds) return '未知';
      customerIds = JSON.parse(customerIds);
      return customerIds.length > 0 ? '指定客户' : '全部';
    },
    scenesText(scenes) {
      if (!scenes) return '未知';
      scenes = JSON.parse(scenes);
      return scenes.length > 0 ? '指定场景' : '全部';
    },
  },
  methods: {
    findPage: payCenterApi.getPayPlatformList,
    handleSearch() {
      this.$refs['pageTable'].onLoad();
    },

    handleEditItem(item) {
      this.currentPaymentItemData = item;
      this.isShow = true;
    },
    changeShowStatus(value, type) {
      this.isShow = value;
      type === 'confirm' && this.handleSearch();
    },
  },
};
</script>

<style type="less" scoped></style>
